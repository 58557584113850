

































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { appointment } from "@/shared/dtos/appointment";
import { appointmentCalendar } from "@/shared/dtos/appointment-calendar";
import appointmentModule from "@/store/modules/appointment-module";
import resourceModule from "@/store/modules/resource-module";
import { DxDateBox } from "devextreme-vue";
import { resource } from "@/shared/dtos/resource";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsDate } from "@/utils/utils-date";
import { ssmMessageService } from "@/shared/services/message-service";
import { RouterNames } from "@/router/routernames";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  components: {
    DxDateBox,
    PacientesSeleccion: () =>
      import("@/views/pacientes/pacientes-seleccion.vue"),
    PacienteFichaShow: () => import("@/views/pacientes/paciente-ficha-show.vue")
  }
})
export default class ModificarCita extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @Prop() Appoiment!: appointment;
  private DefaultResource: resource = new resource().GetResourcePendiente();
  private AuxResource: resource = new resource().GetResourcePendiente();
  private defaultDate: Date = new Date();
  public created() {
    resourceModule.getresources();
  }

  public closeDialog() {
    this.syncedDialog = false;
    this.Appoiment = new appointment();
  }
  public get resources() {
    return resourceModule.resources;
  }

  @Watch("syncedDialog")
  public OpenDialog() {
    if (this.syncedDialog) {
      this.AuxResource = this.Appoiment.Resource;
    }
  }
  public seleccione(seleccion: string) {
    ssmMessageService.alert(
      seleccion,
      "Para poder guardar la cita correctamente",
      ssmMessageService.TypeError
    );
  }
  public AceptarCita() {
    if (
      this.Appoiment.StartDate === undefined ||
      this.Appoiment.EndDate === undefined
    ) {
      this.seleccione("Seleccione un rango de fechas valido");
      return;
    }
    try {
      if (
        this.Appoiment.StartDate.toString() === "" ||
        this.Appoiment.EndDate.toString() === ""
      ) {
        this.seleccione("Seleccione un rango de fechas valido");
        return;
      }
    } catch (error) {
      this.seleccione("Seleccione un rango de fechas valido");
    }
    this.Appoiment.Resource = this.AuxResource
      ? this.AuxResource
      : this.DefaultResource;
    this.Appoiment.ResourceID = this.Appoiment.Resource.ResourceID;

    appointmentModule
      .modificarappointment(this.Appoiment)
      .then(() => this.CitaGuardadaCorrectamente())
      .catch(() => this.seleccione("No se ha podido guardar"));
  }
  public CitaGuardadaCorrectamente() {
    try {
      UtilsNotify.NotificacionSuccess(
        "Cita guardada",
        "Su cita se ha guardado correctamente"
      );
      this.closeDialog();
    } catch (error) {}
  }
}
